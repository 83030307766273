.loading {
  padding: 32px;
  text-align: center;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.sentiment {
  height: 100%;
  align-items: center;
  justify-content: center;
}
