.legendWrapper {
  margin-top: -12px;
  padding-bottom: 2px;
}

.itemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.legendMarker {
  display: flex;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  padding: 8px;
  margin-right: 8px;
}

input[type='checkbox'] {
  display: none;
}

input:checked + label {
  color: gray;
  text-decoration: line-through;
}
