@import './variables.scss';
@import 'fonts.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-menu-vertical .ant-menu-item {
  height: 56px;
  line-height: 56px;
  padding: 0 30px;
  margin: 0;
  border-radius: 0;
  width: 100%;

  .ant-menu-item-icon {
    height: 56px;
  }
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  margin: 0;
}

.ant-divider {
  border-block-start-width: 2px;
}

.ant-btn {
  box-shadow: none;
  font-weight: bold;

  .ant-btn-icon {
    stroke-width: 40;
    stroke: $icon-input;
  }
}
