.loading {
  padding: 32px;
  text-align: center;
}

.tag {
  margin: 0 0 5px 5px;
  vertical-align: middle;
  border-radius: 15px;
}

.switch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  & > span {
    margin: 0 10px;
  }
}

.doughnut {
  padding: 20px 0;
  height: 100%;
}

.flexBox {
  width: 100%;
  height: 100%;
}

.note {
  font-size: 0.8em;
  padding-left: 10px;
}

.title {
  display: inline-block;
  padding: 8px;
  line-height: normal;
}
