@import '../../assets/styles/variables.scss';

.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tileTitle {
  display: inline-block;
  padding: 8px;
  line-height: normal;
  font-size: 16px;
  font-weight: 700;
}

.boxContainer {
  height: 305px;
  width: 100%;
  text-align: center;

  &Graph {
    height: 305px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &Column {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    flex-direction: column;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &Double {
    height: 140px;
    display: flex;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }

    & > div {
      width: 50%;

      &:first-child {
        border-right: 2px dashed rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.box {
  &Number {
    font-size: 32px;
    font-weight: 700;

    @media screen and (max-width: $medium) {
      font-size: 26px;
    }
  }

  &Title {
    font-weight: 500;
  }

  &Subtitle {
    font-size: 12px;
  }

  &Tag {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 4px 12px;
    margin-inline-end: 0;
  }
}

.loading {
  padding: 32px;
  text-align: center;
}

.maxHeightChart {
  max-height: 300px;
}

.boxNumber {
  word-wrap: unset;
}

.boxTitle {
  font-size: 0.95em;

  @media screen and (max-width: $medium) {
    font-size: 12px;
  }
}

.noDateRange {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.summaryBoxesHelp {
  position: absolute;
  right: 0;
  padding-right: 4px;
}

.overallSentimentTrend {
  display: flex;
  align-items: center;
  gap: 4px;
}
