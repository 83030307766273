@import '../../assets/styles/variables.scss';

.graphWrapper {
  height: 100%;
}

.loading {
  padding: 32px;
  text-align: center;
}

.title {
  display: inline-block;
  padding: 8px;
  line-height: normal;
}

.subTitle {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}

.flexColumn {
  display: flex;
  flex-direction: column;

  & > div {
    @media screen and (max-width: $small) {
      display: flex;
      flex-direction: row;
    }
  }
}

.flexBox {
  width: 100%;
  height: 455px;

  &Double {
    height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &:first-of-type {
      margin-bottom: 5px;
      @media screen and (max-width: $small) {
        margin: 0 10px;
      }
    }
  }
}

.titleWithIcon {
  display: flex;
  justify-content: space-between;
}

.helpIcon {
  font-size: 20px;
}

.popoverContent {
  width: 220px;

  .flexLine {
    display: flex;
    justify-content: space-between;
    & > span > span {
      text-decoration: line-through;
    }
  }
}
