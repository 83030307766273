@import '../../assets/styles/variables.scss';

.titleWrapper {
  cursor: pointer;
  width: 196px;
  justify-content: flex-end;
  margin-right: 16px;

  .title {
    font-size: 20px;
  }
}

.wrapper {
  display: flex;

  @media screen and (max-width: $small) {
    flex-direction: column;
  }
}

.popup {
  :global {
    .ant-dropdown-arrow:before {
      background-color: $bg-input;
    }

    .ant-dropdown-menu {
      background-color: $bg-input;
      border-radius: 4px;

      .ant-dropdown-menu-item {
        color: $text;
      }
    }
  }
}
