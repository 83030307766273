.tileTitle {
  display: inline-block;
  padding: 8px;
  line-height: normal;
}

text:hover {
  cursor: pointer;
}

.loading {
  padding: 32px;
  text-align: center;
}
