.negative {
  transform-origin: center;
  transform: scaleY(-1);
  path:nth-child(1) {
    fill: rgba(223, 19, 76, 1);
  }
  path:nth-child(2) {
    stroke: rgba(223, 19, 76, 1);
  }
}

.positive {
  transform-origin: center;
  transform: scaleY(1);
  path:nth-child(1) {
    fill: rgba(8, 136, 86, 1);
  }
  path:nth-child(2) {
    stroke: rgba(8, 136, 86, 1);
  }
}
