.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.timeCol {
  border-top: 1px dashed rgba(255, 255, 255, 0.6);
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.lastCol {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.6);
}

.labelCol {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
}

.timestamps {
  display: flex;
  justify-content: space-between;
  height: 4rem;
  padding-top: 5px;
  & > span {
    font-size: 10px;
  }
}

.legend {
  display: flex;
  margin: 0.5rem;

  &Wrapper {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
      margin: 0 20px;
      align-items: center;

      & > span {
        margin: 0 5px;
      }
    }
  }

  &Circle {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 0 5px;
  }
}
