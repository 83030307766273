.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  button {
    width: 30%;
    margin-bottom: 1rem;
  }

  .footnote {
    font-family: 'Interstate Light', 'Arial Narrow', sans-serif;
    font-weight: 600;
    font-size: 11px;

    a {
      color: white;
      cursor: pointer;
      text-decoration-color: rgba(255, 255, 255, 0.3);

      &:visited {
        color: white;
      }
    }
  }
}
