.loading {
  padding: 32px;
  text-align: center;
}

.filter {
  padding: 0 8px;
  & > :first-child {
    padding-top: 8px;
  }
}

.ticketResTimesSlider {
  :global {
    .ant-slider-mark {
      & > :first-child {
        transform: translateX(0) !important;
        white-space: nowrap;
      }

      & > :last-child {
        transform: translateX(-100%) !important;
        white-space: nowrap;
      }
    }
  }
}

.flexBox {
  width: 100%;
  height: 100%;
}

.title {
  display: inline-block;
  padding: 8px;
  line-height: normal;
}
