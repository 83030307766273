@font-face {
  font-family: "Interstate";
  src: local("Interstate"),
    url("../fonts/Interstate/Interstate Regular.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Light";
  src: local("Interstate Light"),
    url("../fonts/Interstate/Interstate Light.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Bold";
  src: local("Interstate Bold"),
    url("../fonts/Interstate/Interstate Bold.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Italic";
  src: local("Interstate Italic"),
    url("../fonts/Interstate/Interstate Italic.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Light Italic";
  src: local("Interstate Light Italic"),
    url("../fonts/Interstate/Interstate Light Italic.otf") format("opentype");
}

@font-face {
  font-family: "Interstate Bold Italic";
  src: local("Interstate Bold Italic"),
    url("../fonts/Interstate/Interstate Bold Italic.otf") format("opentype");
}