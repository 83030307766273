@import '../../assets/styles/variables.scss';

.datePicker,
.datePicker:focus,
.datePicker:hover {
  background-color: $bg-input;
  border-color: $border-input;
  border-radius: 4px;

  :global {
    .ant-picker-suffix {
      color: $icon-input;
      font-size: 18px;
    }
  }

  input {
    text-align: center;
  }
}

.calendar {
  :global {
    .ant-picker-panel-container {
      background-color: $bg-input;
    }
  }
}
