.loading {
  padding: 32px;
  text-align: center;
}

.title {
  display: inline-block;
  padding: 8px;
  line-height: normal;
}

.popoverContent {
  width: 220px;

  .flexLine {
    display: flex;
    justify-content: space-between;
    & > span > span {
      text-decoration: line-through;
    }
  }
}
