.loading {
  padding: 32px;
  text-align: center;
}

.flexEnd {
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-end;
  & > * {
    margin: 0 5px;
  }
}

.tileTitle {
  display: inline-block;
  padding: 8px;
  line-height: normal;
}

.flexBox {
  display: flex;
  height: 100%;
  width: 100%;
}
