.sentiment {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .sentimentTag {
    margin-left: 4px;
    margin-bottom: 0;
  }
}
