@import '../../assets/styles/variables.scss';

.navbar {
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .sdgeLogo {
      display: flex;
      justify-content: center;
      padding: 12px 0;
      font-size: 64px;
    }

    .menu {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      li:nth-last-child(2) {
        margin-top: auto;
      }
    }
  }
}

.page {
  margin-left: 80px;
  min-height: 100vh;

  .header {
    height: 84px;
    padding: 12px 24px 0 24px;
    display: flex;
    align-items: center;

    .backButton {
      align-self: center;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .insightsToggle {
      line-height: normal;
      align-self: center;
    }

    .insightsTitle {
      width: 280px;
    }

    .pageTitle {
      width: auto;
    }

    .dividerWrapper {
      padding-top: 4px;
      padding-bottom: 2px;
      align-self: normal;
      line-height: normal;

      :global {
        .ant-divider {
          height: 100%;
          border-color: $text;
        }
      }
    }

    .daysToggleWrapper {
      line-height: normal;
    }
  }

  .content {
    padding: 12px 24px;
  }
}
