.inputLabel {
  padding: 0 10px;
}

.additionalFiltersButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.searchButtonWrapper {
  display: flex;
  justify-content: flex-end;
}
