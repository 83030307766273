@import '../../assets/styles/variables.scss';

.chartContainer {
  height: 30px;
  width: 80%;
  margin: 10px 16px;
  display: flex;
}

.chartBar {
  display: flex;
  justify-content: center;
  align-items: center;

  &.positive {
    background-color: rgba(8, 136, 86, 1);
  }

  &.neutral {
    background-color: rgba(243, 155, 49, 1);
  }

  &.negative {
    background-color: rgba(223, 19, 76, 1);
  }

  &.nan {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.innerText {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 2px;
  word-break: keep-all;

  @media screen and (max-width: $medium) {
    font-size: 14px;
  }
}
