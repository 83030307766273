@import '../../assets/styles/variables.scss';

.tileTitle {
  padding: 8px;
}

.loading {
  padding: 32px;
  text-align: center;
}

.callList {
  height: 68vh;
  overflow-y: auto;
  padding: 1px;

  @media screen and (max-width: $large) {
    height: 66vh;
  }

  @media screen and (max-width: $medium) {
    height: 60vh;
  }

  // Ant.d Row gutter fix
  :global {
    .ant-row {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .ant-col:first-child {
      padding-left: 4px !important;
    }

    .ant-col:last-child {
      padding-right: 4px !important;
    }
  }

  .noResultsMessage {
    padding: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .heading {
      margin-bottom: 16px;
    }

    li {
      padding-left: 10px;
    }
  }
}

.callDetail {
  min-height: 200px;
  margin: 20px 0;
  padding: 10px 5px;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    outline: rgba(255, 255, 255, 0.7) 1px solid;
  }

  span {
    margin-bottom: 20px;
  }

  span.heading {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .segment {
    display: flex;
    flex-direction: column;

    & > .heading {
      margin-left: 20px;
    }

    .details {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 5px;
      justify-content: space-between;
      .twoCols {
        display: flex;
        flex-direction: column;
        padding-right: 8px;
        span {
          margin-bottom: 20px;
        }
        span.heading {
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
    }
  }
}
