$bg-primary: #031627;
$bg-secondary: #0e263b;
$bg-input: #3e5162;
$border-input: #ffffff;
$icon-input: #ffffff;
$text: #ffffff;

$small: 992px;
$medium: 1367px;
$large: 1600px;
