.login {
  display: flex;

  &Form {
    width: 35%;
  }

  &Background {
    width: 65%;
    height: 100vh;
    background: url('../../assets/images/login-background.png') no-repeat center
      center;
    background-size: cover;
  }
}
