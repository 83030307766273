.callSummary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .description {
    line-height: 1.5;
  }
}
