.loading {
  padding: 32px;
  text-align: center;
}

.tileTitle {
  display: inline-block;
  padding: 8px;
  line-height: normal;
}

.flexBox {
  display: flex;
  width: 100%;
  height: 100%;
}
