@import '../../assets/styles/variables.scss';

.areaTitle {
  display: inline-block;
  padding: 8px;
  line-height: normal;
}

.colHeight {
  height: 100%;

  .verticalArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;

    @media screen and (max-width: $medium) {
      & > h1 {
        font-size: 26px;
      }
    }
  }

  .longNumber {
    font-size: 30px;
    @media screen and (max-width: $medium) {
      font-size: 22px !important;
    }
  }
}

.graphBox {
  display: flex;
  height: 100%;
  align-items: center;

  .graphWrapper {
    width: 100%;
  }
}

.loading {
  padding: 32px;
  text-align: center;
}
