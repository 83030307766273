@import '../../assets/styles/variables.scss';

.select {
  :global {
    .ant-select-selector {
      background-color: $bg-input !important;
      border-color: $border-input !important;
      border-radius: 4px;
    }

    .ant-select-arrow {
      color: $icon-input;
    }
  }
}

.selectPopup {
  background-color: $bg-input;
  border-radius: 4px;
}
