.loading {
  padding: 32px;
  text-align: center;
}

.switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  & > span {
    margin: 0 10px;
  }
}

.subInfo {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 42px;

  & > span {
    margin: 0 10px;
  }
}

.flexBox {
  width: 100%;
  height: 100%;
}

.wordBox {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.title {
  display: inline-block;
  padding: 8px;
  line-height: normal;
}
