.message:not(:last-child) {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .messageText {
    font-size: 13px;
  }
}
