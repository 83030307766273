@import '../../assets/styles/variables.scss';

.loginForm {
  background-color: $bg-primary;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .logo {
    margin-top: 5rem;

    img {
      height: 70%;
    }
  }

  .info {
    margin: 1rem 5rem;
    text-align: center;
    margin-bottom: 3rem;
  }

  .footnote {
    font-size: 12px;
    text-align: center;
    margin: 3rem;
    font-family: 'Interstate Light', 'Arial Narrow', sans-serif;
  }
}
