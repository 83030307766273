@import '../../assets/styles/variables.scss';

.tableDesign {
  :global {
    .ant-table-thead .ant-table-cell {
      background-color: $bg-secondary;
      border-width: 3px;
      border-color: rgb(255, 255, 255);
    }
  }

  overflow: auto;
  max-height: 46vh;

  @media screen and (max-width: $large) {
    max-height: 43vh;
  }

  @media screen and (max-width: $medium) {
    max-height: 33vh;
  }

  .tableLightRow {
    background-color: $bg-input;
    border-bottom: none;
  }
  .tableDarkRow {
    background-color: $bg-secondary;
  }
}

.narrow {
  max-height: 39vh;

  @media screen and (max-width: $large) {
    max-height: 35vh;
  }

  @media screen and (max-width: $medium) {
    max-height: 22vh;
  }
}
