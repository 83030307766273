.searchBar {
  position: absolute;
  top: 25px;
  right: 20px;
  width: 260px;

  :global {
    .ant-input {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: #fff;
      border-radius: 16px 0 0 16px;

      &:focus {
        border-color: #fff;
        box-shadow: none;
      }

      &-group-addon {
        border-radius: 0 16px 16px 0 !important;
      }

      &-search-button {
        background-color: rgba(255, 255, 255, 0.1);
        border-color: #fff;
        border-radius: 0 16px 16px 0 !important;
        color: #fff !important;

        &:focus {
          border-color: #fff;
          box-shadow: none;
        }

        &:active {
          border-color: #fff;
          box-shadow: none;
        }
      }
    }
  }
}
