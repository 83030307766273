@import '../../assets/styles/variables.scss';

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .pageSelector {
    display: inline-flex;
    p {
      width: 20px;
      text-align: center;
    }
  }

  .pageCounter {
    width: 160px;
    text-align: end;

    input {
      width: 50px;
      margin: 0 5px;
      text-align: center;
    }
  }

  &Button {
    color: white;
    background: none;
    border: none;
    cursor: pointer;

    &:disabled {
      cursor: default;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}
